var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "my-3" },
            [_c("b-col", [_c("h2", [_vm._v("Help Topic Search")])])],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "helpForm",
                      attrs: { action: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.search()
                        }
                      }
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-input", {
                                    attrs: { placeholder: "Help Topic Search" },
                                    model: {
                                      value: _vm.form.keywords,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "keywords", $$v)
                                      },
                                      expression: "form.keywords"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.search()
                                            }
                                          }
                                        },
                                        [_vm._v("Search")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          this.searchresults.length > 0
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c("h5", [_vm._v("Search Results")]),
                  _vm._l(_vm.searchresults, function(row) {
                    return _c(
                      "b-row",
                      { key: row.id },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-left",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal",
                                            value: "helpanswermodal",
                                            expression: "'helpanswermodal'"
                                          }
                                        ],
                                        staticClass: "default-answer-link",
                                        attrs: {
                                          href: "#",
                                          user: "'row'",
                                          "no-close-on-backdrop": true
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setModalInfo(row)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(row.question) + " ")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.searched && _vm.searchresults.length === 0
            ? _c("div", { staticClass: "mt-5" }, [
                _c("h5", [_vm._v("No Results")])
              ])
            : _vm._e(),
          _vm.searched
            ? _c("div", { staticClass: "text-center mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.clear()
                      }
                    }
                  },
                  [_vm._v(" Clear Search ")]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c("br"),
      this.searchresults.length == 0 && !_vm.searched && _vm.faq.length > 0
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "my-3" },
                [
                  _c("b-col", [
                    _c("h2", [_vm._v("Frequently Asked Questions")])
                  ])
                ],
                1
              ),
              _vm._l(_vm.faq, function(row) {
                return _c(
                  "b-row",
                  { key: row.id },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-left",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal",
                                        value: "helpanswermodal",
                                        expression: "'helpanswermodal'"
                                      }
                                    ],
                                    staticClass: "default-answer-link",
                                    attrs: { href: "#", user: "'row'" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setModalInfo(row)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(row.question) + " ")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c("br"),
      this.searchresults.length == 0 && !_vm.searched && _vm.videos.length > 0
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "my-3" },
                [_c("b-col", [_c("h2", [_vm._v("How-To Videos")])])],
                1
              ),
              _c(
                "b-row",
                _vm._l(_vm.videos, function(row) {
                  return _c("b-col", { key: row.id, attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "thumbpad" }, [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: "helpanswermodal",
                              expression: "'helpanswermodal'"
                            }
                          ],
                          staticClass: "default-video-link",
                          attrs: { href: "#", user: "'row'" },
                          on: {
                            click: function($event) {
                              return _vm.setModalInfo(row)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "thumb-border" },
                            [
                              _c("b-embed", {
                                attrs: {
                                  type: "video",
                                  src: row.answers[0].url
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" " + _vm._s(row.question) + " ")
                        ]
                      )
                    ])
                  ])
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "helpanswermodal",
            "no-close-on-backdrop": true,
            title: _vm.modalinfo.question,
            size: "xl",
            "ok-only": "",
            "ok-variant": "success"
          }
        },
        [
          _c(
            "b-row",
            _vm._l(_vm.modalinfo.answers, function(a) {
              return _c("b-col", { key: a.answerid, attrs: { cols: "12" } }, [
                a.content.length > 0
                  ? _c("div", { domProps: { innerHTML: _vm._s(a.content) } })
                  : _vm._e(),
                a.answertype == "Help Screenshot"
                  ? _c("div", { staticClass: "text-center" }, [
                      _c("img", { attrs: { src: a.url } })
                    ])
                  : _vm._e(),
                a.answertype == "Help Video"
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-embed",
                          {
                            attrs: {
                              type: "video",
                              aspect: "16by9",
                              controls: "",
                              allowfullscreen: ""
                            }
                          },
                          [
                            _c("source", {
                              attrs: { src: a.url, type: "video/mp4" }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }